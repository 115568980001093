import {
  featureFlags,
  getSelection,
  usePlayback,
  ViewportModule,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  ChildrenRelationsAspect,
  ColorStopsRelationsAspect,
  commitUndo,
  EffectsRelationsAspect,
  Entity,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  FillsRelationsAspect,
  Frame,
  Group,
  Project,
  SelectionSystem,
  StrokesRelationsAspect,
  TargetRelationAspect,
  unwrap,
  UpdatesSystem,
  wrap,
} from '@aninix-inc/model'
import { useClipboard } from '@aninix/clipboard'
import { useReloadOnAnyUpdate } from '@aninix/core'
import { ContextMenuItem } from '@aninix/editor/stores/ui'
import { clearAnimation } from '@aninix/editor/use-cases/clear-animation'
import { copyPositionKeyframes } from '@aninix/editor/use-cases/copy-position-keyframes'
import { duplicateLayer } from '@aninix/editor/use-cases/duplicate-layer'
import { useUi } from '../../stores'
import { AnimationQuickPresets } from '../animation-quick-presets'
import * as styles from './index.scss'

export interface IProps {
  project: Project
}
export const Viewport: React.FCC<IProps> = observer(({ project }) => {
  useReloadOnAnyUpdate(project)
  const propertyMenuViewedTimes = React.useRef<number>(0)
  const [quickPresetsViewed, setQuickPresetsViewed] = React.useState(false)

  const uiStore = useUi()
  const playback = usePlayback()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  const canEdit = uiStore.allowedAction === 'full'

  const isPropertyMenuVisible = (() => {
    const selectedNodes = getSelection(project, EntityType.Node).filter(
      (entity) => entity.hasComponent(EntryComponent) === false
    )

    if (canEdit === false) {
      return
    }

    if (uiStore.propertiesPanelActiveTab === 'Presets') {
      return false
    }

    if (selectedNodes.length === 0) {
      return false
    }

    const keyframes = project.getEntitiesByPredicate(
      (entity) =>
        entity.getComponentOrThrow(EntityTypeComponent).value ===
        EntityType.Keyframe
    )
    // @TODO: FIXME IMPORTANT. Related to ANI-1348
    // The dirty fix to ignore DISCONNECTED keyframes.
    // Which can happen when user copy-pasted keyframes for linked entities (fills/strokes/trim-path/effect etc)
    const relatedEntityIds = new Set(
      keyframes
        // @NOTE: check for nullability required in cases when there is a keyframe
        // which pointed to removed layer.
        .filter(
          (keyframe) =>
            keyframe.getAspect(TargetRelationAspect)?.getTargetEntity() != null
        )
        .map(
          (keyframe) =>
            keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getTargetEntityOrThrow().id
        )
    )

    // @NOTE: odd style of code but it's very performant because we have early returns.
    // @TODO: refactor to remove code duplications.
    for (const node of selectedNodes) {
      if (relatedEntityIds.has(node.id)) {
        return false
      }

      if (node.hasAspect(ChildrenRelationsAspect)) {
        for (const entity of node
          .getAspectOrThrow(ChildrenRelationsAspect)
          .getChildrenList()) {
          if (relatedEntityIds.has(entity.id)) {
            return false
          }
        }
      }

      if (node.hasAspect(FillsRelationsAspect)) {
        for (const entity of node
          .getAspectOrThrow(FillsRelationsAspect)
          .getChildrenList()) {
          if (entity.hasAspect(ColorStopsRelationsAspect)) {
            for (const entity2 of entity
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()) {
              if (relatedEntityIds.has(entity2.id)) {
                return false
              }
            }
          }

          if (relatedEntityIds.has(entity.id)) {
            return false
          }
        }
      }

      if (node.hasAspect(StrokesRelationsAspect)) {
        for (const entity of node
          .getAspectOrThrow(StrokesRelationsAspect)
          .getChildrenList()) {
          if (entity.hasAspect(ColorStopsRelationsAspect)) {
            for (const entity2 of entity
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()) {
              if (relatedEntityIds.has(entity2.id)) {
                return false
              }
            }
          }

          if (relatedEntityIds.has(entity.id)) {
            return false
          }
        }
      }

      if (node.hasAspect(EffectsRelationsAspect)) {
        for (const entity of node
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()) {
          if (relatedEntityIds.has(entity.id)) {
            return false
          }
        }
      }
    }

    propertyMenuViewedTimes.current += 1
    return true
  })()

  React.useEffect(() => {
    if (
      isPropertyMenuVisible === false &&
      quickPresetsViewed === false &&
      propertyMenuViewedTimes.current > 0
    ) {
      // @TODO: EDITOR provider correct API
      // legacyService.updateWhatsNew({ quickPresetsViewed: true })
      setQuickPresetsViewed(true)
    }
  }, [quickPresetsViewed, isPropertyMenuVisible])

  // @TODO: EDITOR provider correct API
  // React.useEffect(() => {
  //   legacyService.getWhatsNew().then((result) => {
  //     if (result instanceof Error) {
  //       return
  //     }

  //     setQuickPresetsViewed(result?.quickPresetsViewed || false)
  //   })
  // }, [])

  const clipboard = useClipboard()
  const selection = project.getSystemOrThrow(SelectionSystem)

  const openContextMenu = React.useCallback(
    (layers: Entity<unknown>[], e: React.MouseEvent<Element, MouseEvent>) => {
      const array: Array<ContextMenuItem | 'divider'> = []

      if (layers.length === 1) {
        // @TODO: IMPORTANT refactor. Move to common place or service
        array.push({
          title: 'Copy as position keyframes',
          // @TODO: enable
          onClick: copyPositionKeyframes({
            layer: layers[0],
            project,
            clipboard,
            uiStore,
          }),
        })
      }

      if (featureFlags.frameSelection) {
        array.push({
          title: 'Frame selection',
          onClick: () => {
            updates.batch(() => {
              const frame = wrap(layers, {
                time: playback.time,
                Constructor: Frame,
              })
              // @TODO: set cool name for created frame here
              selection.replace([frame.id])
            })
            commitUndo(project)
            uiStore.closeContextMenu()
          },
        })

        array.push({
          title: 'Group selection',
          onClick: () => {
            updates.batch(() => {
              const group = wrap(layers, {
                time: playback.time,
                Constructor: Group,
              })
              // @TODO: set cool name for created group here
              selection.replace([group.id])
            })
            commitUndo(project)
            uiStore.closeContextMenu()
          },
        })

        array.push({
          title: 'Ungroup',
          onClick: () => {
            selection.deselectAll()
            updates.batch(() => {
              let entities: Entity[] = []
              for (const layer of layers) {
                if (layer.hasAspect(ChildrenRelationsAspect)) {
                  const unwrappedLayers = unwrap(layer, { time: playback.time })
                  entities.push(...unwrappedLayers)
                }
              }
              selection.replace(entities.map((e) => e.id))
            })
            commitUndo(project)
            uiStore.closeContextMenu()
          },
        })
      }

      array.push({
        title: 'Duplicate',
        onClick: () => {
          updates.batch(() => {
            for (const layer of layers) {
              duplicateLayer(layer, project, uiStore)()
            }
          })
          commitUndo(project)
        },
      })

      array.push({
        title: 'Clear animation',
        onClick: clearAnimation({ project, uiStore }),
      })

      uiStore.openContextMenu(array, e)
    },
    [uiStore, clipboard, project]
  )

  const handleContext = React.useCallback(
    (e: React.MouseEvent) => {
      const entities = selection.getEntitiesByEntityType(EntityType.Node)

      if (entities.length === 0) {
        return
      }

      openContextMenu(entities, e)
    },
    [selection, openContextMenu]
  )

  return (
    <div className="relative h-full w-full">
      {isPropertyMenuVisible && (
        <div className={styles['property-menu']}>
          <AnimationQuickPresets
            project={project}
            quickPresetsViewed={quickPresetsViewed}
          />
        </div>
      )}
      <ViewportModule
        project={project}
        propertyMenu={null}
        editable={canEdit}
        onContextMenu={handleContext}
      />
    </div>
  )
})

Viewport.displayName = 'Viewport'
