import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  Project,
  createComponentsProvider,
  createEntitiesProvider,
  createSystemsProvider,
} from '@aninix-inc/model'
import { getAsset, syncProjectWithRemote } from '@aninix/api'
import {
  IImageProvider,
  ImagesStore,
  getBase64FromImage,
  uint8ArrayToBase64,
} from '@aninix/core'
import { useLogger } from '@aninix/logger'
import * as React from 'react'

// @NOTE: used to resize images before render.
// The bigger number the better quality but slower rendering speed.
// @TODO: this caused issue with CROP type of images.
// If image clamped by this size then we have doubled images count in grid.
const IMAGE_LIMIT_IN_PIXELS_BY_BIGGER_SIDE = 4096

// @TODO: refactor all IImageProviders. Now this is mess
export class ImageProvider implements IImageProvider {
  private async getImagesByHashes(hashes: string[]): Promise<Uint8Array[]> {
    const remoteImageEntries = await Promise.all(
      hashes.map(async (hash) => {
        return [hash, await getAsset({ hash })]
      })
    )
    const imagesMap = Object.fromEntries(remoteImageEntries) as Record<
      string,
      Uint8Array
    >
    return hashes.map((hash) => imagesMap[hash])
  }

  // @NOTE base 64 images but resized by bigger size (v3)
  getImages: IImageProvider['getImages'] = async (hashes) => {
    const data = await this.getImagesByHashes(hashes)

    if (data.length !== hashes.length) {
      return {}
    }

    let images: Record<string, HTMLImageElement> = {}

    const loadedImages = await Promise.all(
      hashes.map(
        (_, idx) =>
          new Promise<HTMLImageElement>((resolve, reject) => {
            const actualData = data || []
            const image = new Image()
            const imageData = actualData[idx]
            image.src = `data:image/jpg;base64, ${uint8ArrayToBase64(
              imageData
            )}`
            image.onload = () => {
              resolve(image)
            }
            image.onerror = (err) => {
              reject(err)
            }
          })
      )
    )

    loadedImages.forEach((sourceImage, idx) => {
      const imageData = getBase64FromImage(
        sourceImage,
        IMAGE_LIMIT_IN_PIXELS_BY_BIGGER_SIDE
      )
      const image = new Image()
      image.src = imageData
      const hash = hashes[idx]
      images[hash] = image
    })

    return images
  }
}

export interface IInspectorInteractor {
  state:
    | 'loading'
    | 'success'
    | 'error'
    | 'login-required'
    | 'payment-required'
    | 'not-found'
    | 'locked'
  project?: Project
  imageProvider?: IImageProvider
}

type Payload = {
  projectId?: string
  imagesStore?: ImagesStore
}

export const useGetRemoteProject = ({
  projectId,
  imagesStore,
}: Payload): IInspectorInteractor => {
  const logger = useLogger()
  const [state, setState] =
    React.useState<IInspectorInteractor['state']>('loading')
  const [imageProvider, setImageProvider] = React.useState<
    IImageProvider | undefined
  >(undefined)
  const [localProject, setLocalProject] = React.useState<Project | undefined>(
    undefined
  )

  const loadProject = React.useCallback(async () => {
    try {
      if (projectId == null) {
        setState('error')
        return
      }

      setImageProvider(new ImageProvider())
      const project = new Project({
        id: projectId,
        componentsProvider: createComponentsProvider(),
        entitiesProvider: createEntitiesProvider(),
        systemsProvider: createSystemsProvider(),
      })
      await syncProjectWithRemote(project)
      setLocalProject(project)
      setState('success')
    } catch (err: any) {
      logger.error('Project loading error', err)
      switch (err.statusCode) {
        case 404:
          setState('not-found')
          return
        case 401:
          setState('login-required')
          return
        case 402:
          setState('payment-required')
          return
        case 417:
          setState('locked')
          return
        default:
          setState('error')
      }
    }
  }, [])

  React.useEffect(() => {
    loadProject()
  }, [])

  React.useEffect(() => {
    if (imagesStore == null || state === 'loading' || localProject == null) {
      return
    }

    // @NOTE: trigger store to load all images
    const imagePaints = localProject
      .getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
            EntityType.Paint && e.hasComponent(HashComponent)
      )
      .map((paint) => paint.getComponentOrThrow(HashComponent).value)

    imagesStore
      .clean()
      .loadImagesByHashes(imagePaints.map((paint: any) => paint.hash))
  }, [imagesStore, state, localProject])

  return {
    state,
    project: localProject,
    imageProvider,
  }
}
