import {
  Entity,
  getAbsoluteTransformMatrix,
  NodeColorComponent,
  Point2D,
} from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { nodeColors, useCursor } from '@aninix/core'
import {
  KeyModificator,
  usePathEditor,
  usePlayback,
  useSession,
  useViewport,
} from '@aninix/core/stores'
import { BezierPoint } from '@aninix/core/vector-helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  SnapConstraints,
  Snapping,
  SnappingItem,
  snapPointToPoints,
} from '../snapping'
import { RegionHandle, RegionPoint } from './region-point'
import { RegionStroke } from './region-stroke'
import {
  AbsoluteItem,
  BezierPath,
  BezierRegion,
  bezierRegionToSvgPath,
  findClosestPoint,
  getIntersectionItems,
  getRegionPointSelection,
  getRelativeItems,
  getUpdatedPointWithTangents,
  getUpdatedPointWithToggledTangents,
  insertPointIntoSegment,
  omitSelectedPoints,
  svgPathToBezierRegion,
  TangentMirroring,
} from './utils'

export interface IProps {
  node: Entity
  vectorPath: VectorPath
  regionIndex: number
  selectionRectBoundingRect?: {
    x: number
    y: number
    width: number
    height: number
  }
  onUpdate: (data: string) => void
  onRemovePoint: (args: { pathIndex: number; pointIndex: number }) => void
  onEndChange: () => void
}

export const Region: React.FCC<IProps> = observer(
  ({
    node,
    vectorPath,
    regionIndex,
    selectionRectBoundingRect,
    onUpdate,
    onEndChange,
    onRemovePoint,
  }) => {
    const playback = usePlayback()
    const viewport = useViewport()
    const session = useSession()
    const hasCtrl = session.keyModificators.includes(KeyModificator.Ctrl)
    const hasShift = session.keyModificators.includes(KeyModificator.Shift)
    const pathEditor = usePathEditor()

    const selectedPoints = pathEditor.getSelectedPoints(regionIndex)
    const selectedTangents = pathEditor.getSelectedTangents(regionIndex)

    const [currentHandle, setCurrentHandle] =
      React.useState<RegionHandle | null>(null)
    const [snappingItems, setSnappingItems] = React.useState<SnappingItem[]>([])

    const { setCursor, clearCursor } = useCursor()

    const startPoint = React.useRef<BezierPoint | null>(null)

    const absoluteTransformMatrix = new paper.Matrix(
      getAbsoluteTransformMatrix({ entity: node, time: playback.time })
    )
    const matrixFingerprint = absoluteTransformMatrix.toString()

    const [region, absoluteGlobalItems] = React.useMemo(() => {
      const region = svgPathToBezierRegion(vectorPath.data)
      console.log('[svg-path-editor] create bezier region: ', region)
      const absoluteGlobalItems = region.flatMap((path, pathIndex) =>
        path.points.map<AbsoluteItem>((point, pointIndex) => {
          const absolutePoint = point.toAbsoluteJson()
          return {
            point: absoluteTransformMatrix.transform(absolutePoint.point),
            startTangent: absoluteTransformMatrix.transform(
              absolutePoint.startTangent
            ),
            endTangent: absoluteTransformMatrix.transform(
              absolutePoint.endTangent
            ),
            indexes: { regionIndex, pathIndex, pointIndex },
          }
        })
      )
      return [region, absoluteGlobalItems] as const
    }, [vectorPath.data, matrixFingerprint, regionIndex])

    const initialRegion = React.useRef<BezierRegion | null>(null)

    React.useEffect(() => {
      if (!selectionRectBoundingRect) return

      pathEditor.select(
        getIntersectionItems(absoluteGlobalItems, selectionRectBoundingRect)
      )
    }, [selectionRectBoundingRect, absoluteGlobalItems, regionIndex])

    const move = React.useCallback(
      (movement: Point2D, point: BezierPoint) => {
        if (!startPoint.current || !currentHandle) return

        const localPoint = startPoint.current.toAbsoluteJson()
        const globalPoint = absoluteTransformMatrix.transform(localPoint.point)

        const isStartTangent = currentHandle === RegionHandle.StartTangent

        const localStart = selectedPoints.length
          ? localPoint.point
          : isStartTangent
            ? localPoint.startTangent
            : localPoint.endTangent

        const globalStart = absoluteTransformMatrix.transform(localStart)

        const globalCursorPosition = new paper.Point(
          globalStart.x + movement.x,
          globalStart.y + movement.y
        )

        const globalTargets = (
          selectedPoints.length
            ? omitSelectedPoints(absoluteGlobalItems, selectedPoints)
            : absoluteGlobalItems
        ).map((p) => p.point)

        const [globalSnappedPoint, globalItems, constraint] = snapPointToPoints(
          {
            currentPoint: globalCursorPosition,
            targets: globalTargets,
            distance: 5 / viewport.zoom,
            constraints: [
              SnapConstraints.Diagonal,
              SnapConstraints.Horizontal,
              SnapConstraints.Vertical,
            ],
            startPoint:
              !selectedPoints.length && selectedTangents.length
                ? globalPoint
                : globalStart,
            shouldApplyConstraint: hasShift,
          }
        )

        if (constraint && constraint !== SnapConstraints.Diagonal) {
          setCursor(
            `arrow-move-${
              constraint === SnapConstraints.Horizontal
                ? 'horizontal'
                : 'vertical'
            }`
          )
        } else {
          setCursor('arrow-move')
        }

        setSnappingItems(globalItems)

        const localSnappedPoint =
          absoluteTransformMatrix.inverseTransform(globalSnappedPoint)

        const delta = {
          x: localSnappedPoint.x - localStart.x,
          y: localSnappedPoint.y - localStart.y,
        }

        const pointDelta = {
          x: localSnappedPoint.x - point.point.x,
          y: localSnappedPoint.y - point.point.y,
        }

        if (selectedPoints.length) {
          selectedPoints.forEach((p) =>
            movePoint(p.pathIndex, p.pointIndex, pointDelta)
          )
        } else if (selectedTangents.length) {
          let mirroring = TangentMirroring.None

          if (hasCtrl) {
            setCursor('arrow-tangent')
            mirroring = TangentMirroring.AngleAndLength
          }

          selectedTangents.forEach((t) =>
            moveTangent(t.pathIndex, t.pointIndex, t.tangent, delta, mirroring)
          )
        }

        onUpdate(bezierRegionToSvgPath(region))
      },
      [
        selectedPoints,
        selectedTangents,
        hasCtrl,
        hasShift,
        region,
        absoluteGlobalItems,
        onUpdate,
        setCursor,
      ]
    )

    const movePoint = React.useCallback(
      (pathIndex: number, pointIndex: number, offset: Point2D) => {
        const currentPoint = region[pathIndex].points[pointIndex].clone()
        const updatedPoint = currentPoint.add({
          point: offset,
        } as BezierPoint)
        region[pathIndex].points[pointIndex] = updatedPoint
      },
      [region]
    )

    const moveTangent = React.useCallback(
      (
        pathIndex: number,
        pointIndex: number,
        tangent: 'start' | 'end',
        delta: Point2D,
        mirroring: TangentMirroring
      ) => {
        if (!startPoint.current || !initialRegion.current) return
        const currentPoint = region[pathIndex].points[pointIndex].clone()
        const initialTangent =
          initialRegion.current[pathIndex].points[pointIndex][
            tangent === 'start' ? 'startTangent' : 'endTangent'
          ]

        if (!initialTangent) return

        const position = {
          x: initialTangent.x + delta.x,
          y: initialTangent.y + delta.y,
        }

        const updatedPoint = getUpdatedPointWithTangents(
          currentPoint,
          tangent,
          position,
          mirroring
        )

        console.log(updatedPoint.startTangent, updatedPoint.endTangent)

        region[pathIndex].points[pointIndex] = updatedPoint
      },
      [region, regionIndex]
    )

    const toggleTangents = React.useCallback(
      (
        point: BezierPoint,
        pointIndex: number,
        path: BezierPath,
        pathIndex: number
      ) => {
        if (currentHandle !== null) return
        const updatedPoint = getUpdatedPointWithToggledTangents({
          point,
          path,
          pointIndex,
        })
        region[pathIndex].points[pointIndex] = updatedPoint
        pathEditor.replaceSelection([
          {
            pathIndex,
            pointIndex,
            regionIndex,
          },
        ])
        onUpdate(bezierRegionToSvgPath(region))
        onEndChange()
      },
      [region, currentHandle, regionIndex, onEndChange]
    )

    const createPoint = React.useCallback(
      (point: Point2D) => {
        if (region.length === 0 || currentHandle !== null) return

        const { closestPathIndex, closestPointIndex } = findClosestPoint(
          region,
          point
        )

        insertPointIntoSegment(
          region,
          closestPathIndex,
          closestPointIndex,
          point
        )

        pathEditor.replaceSelection([
          {
            pathIndex: closestPathIndex,
            pointIndex: closestPointIndex + 1,
            regionIndex,
          },
        ])

        onUpdate(bezierRegionToSvgPath(region))
        onEndChange()
      },
      [region, currentHandle, onUpdate, regionIndex, onEndChange]
    )

    const removeTangent = React.useCallback(
      ({
        pathIndex,
        pointIndex,
        tangent,
      }: {
        pathIndex: number
        pointIndex: number
        tangent: 'start' | 'end'
      }) => {
        if (currentHandle !== null) return
        region[pathIndex].points[pointIndex][
          tangent === 'start' ? 'resetStartTangent' : 'resetEndTangent'
        ]()

        pathEditor.replaceSelection([
          {
            pathIndex,
            pointIndex,
            regionIndex,
          },
        ])

        onUpdate(bezierRegionToSvgPath(region))
        onEndChange()
      },
      [region, pathEditor, currentHandle, onUpdate, onEndChange]
    )

    const startMove = React.useCallback(
      (handle: RegionHandle, point: BezierPoint) => {
        setCurrentHandle(handle)

        setCursor('arrow-move')

        const clonedPoint = point.clone()

        startPoint.current = clonedPoint
        initialRegion.current = JSON.parse(
          JSON.stringify(region)
        ) as BezierRegion
      },
      [absoluteTransformMatrix, selectedTangents, region, setCursor]
    )

    const endMove = React.useCallback(() => {
      setCurrentHandle(null)
      startPoint.current = null
      setSnappingItems([])
      onEndChange()
      clearCursor()
    }, [onEndChange, clearCursor])

    const select = React.useCallback(
      ({
        handle,
        pointIndex,
        pathIndex,
      }: {
        handle: RegionHandle
        pointIndex: number
        pathIndex: number
      }) => {
        const relativeItems = getRelativeItems({
          regionIndex,
          pathIndex,
          pointIndex,
          handle,
          region,
        })

        if (hasShift) {
          pathEditor.select(relativeItems)
        } else {
          pathEditor.replaceSelection(relativeItems)
        }
      },
      [selectedTangents, region, regionIndex, hasShift, currentHandle]
    )

    return (
      <>
        <RegionStroke
          absoluteTransformMatrix={absoluteTransformMatrix}
          pathData={vectorPath.data}
          zoom={viewport.zoom}
          color={nodeColors[node.getComponentOrThrow(NodeColorComponent).value]}
          onCreatePoint={createPoint}
          allowCreate={currentHandle === null}
        />

        {region.map((path, pathIndex) =>
          path.points.map((point, pointIndex) => {
            return (
              <RegionPoint
                key={`node-${node.id}-path-${pathIndex}-bezier-${pointIndex}`}
                node={node}
                point={point}
                absoluteTransformMatrix={absoluteTransformMatrix}
                isMoving={currentHandle !== null}
                onMove={(offset) => move(offset, point)}
                selection={getRegionPointSelection({
                  pathEditor,
                  pathIndex,
                  pointIndex,
                  region,
                  regionIndex,
                })}
                onStartMove={(handle) => startMove(handle, point)}
                onEndMove={endMove}
                onRemoveTangent={(tangent) =>
                  removeTangent({ pathIndex, pointIndex, tangent })
                }
                onRemovePoint={() => onRemovePoint({ pathIndex, pointIndex })}
                onToggleTangents={() =>
                  toggleTangents(point, pointIndex, path, pathIndex)
                }
                onSelect={(handle) => select({ handle, pointIndex, pathIndex })}
              />
            )
          })
        )}

        {currentHandle &&
          snappingItems.map((item) => (
            <Snapping
              item={item}
              zoom={viewport.zoom}
              key={
                item instanceof paper.Point
                  ? `${item.x}.${item.y}`
                  : `${item.start.x}.${item.start.y}.${item.end.x}.${item.end.y}`
              }
            />
          ))}
      </>
    )
  }
)

Region.displayName = 'Region'
