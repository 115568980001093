import {
  EntityType,
  EntityTypeComponent,
  Root,
  SelectionSystem,
  TimeComponent,
  getDuration,
} from '@aninix-inc/model'
import {
  useMouseMove,
  useThrottle,
  useWindowResize,
} from '@aninix/app-design-system'
import { usePlayback, useProject, useTimeline } from '@aninix/core'
import { CommentsTimeline } from '@aninix/core/modules/comments-timeline'
import { getVectorAngle } from '@aninix/core/utils'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import * as styles from './index.scss'
import { Info } from './info'
import { ScrollSyncProvider } from './scroll-sync'
import { Tracks } from './tracks'

export const Timeline: React.FC = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const timeline = useTimeline()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const projectDuration = getDuration(project.getEntityByTypeOrThrow(Root))
  const prevPreviewRangeStartRef = React.useRef<number | undefined>()
  const prevPreviewRangeDurationRef = React.useRef<number | undefined>()

  const width = timeline.infoWidth
  const bufferTimelineHeightRef = React.useRef(0)
  const minTimelineHeightRef = React.useRef(40)
  const maxTimelineHeightRef = React.useRef(document.body.clientHeight * 0.75)

  React.useEffect(
    function ghostSliderAndDynamicRangeSync() {
      return selection.onSelectionUpdate((prev, current) => {
        const prevSelectedKeyframes = prev.flatMap((entityId) => {
          const entity = project.getEntityOrThrow(entityId)
          return entity.getComponent(EntityTypeComponent)?.value ===
            EntityType.Keyframe
            ? [entity]
            : []
        })
        const currentSelectedKeyframes = current.flatMap((entityId) => {
          const entity = project.getEntityOrThrow(entityId)
          return entity.getComponent(EntityTypeComponent)?.value ===
            EntityType.Keyframe
            ? [entity]
            : []
        })

        // @NOTE: first keyframe selected
        if (
          prevSelectedKeyframes.length === 0 &&
          currentSelectedKeyframes.length === 1
        ) {
          playback.setGhostTime()
        }

        // @NOTE: jump to last selected keyframe.
        // Related to ANI-2457.
        if (currentSelectedKeyframes.length >= 1) {
          playback.updateTime(
            R.last(currentSelectedKeyframes)!.getComponentOrThrow(TimeComponent)
              .value
          )
        }

        // @NOTE: all deselected
        if (
          currentSelectedKeyframes.length === 0 &&
          playback.ghostTime != null
        ) {
          // @NOTE: some layers kept selected
          if (prevSelectedKeyframes.length > 0 && current.length === 0) {
            playback.updateTime(playback.ghostTime)
          }

          playback.resetGhostTime()
        }

        // @NOTE: update preview range
        if (
          prevPreviewRangeStartRef.current == null &&
          prevPreviewRangeDurationRef.current == null
        ) {
          prevPreviewRangeStartRef.current = playback.previewRange.start
          prevPreviewRangeDurationRef.current = playback.previewRange.duration
        }

        // @NOTE: 2 or more keyframes selected
        if (currentSelectedKeyframes.length >= 2) {
          const keyframeTimes = currentSelectedKeyframes.map(
            (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value
          )
          const minTime = Math.min(...keyframeTimes)
          const maxTime = Math.max(...keyframeTimes)

          if (minTime !== maxTime) {
            playback.updatePreviewRangeStart(minTime)
            playback.updatePreviewRangeEnd(maxTime)
          }
        } else if (
          prevPreviewRangeStartRef.current != null &&
          prevPreviewRangeDurationRef.current != null
        ) {
          playback.updatePreviewRangeStart(prevPreviewRangeStartRef.current)
          playback.updatePreviewRangeDuration(
            prevPreviewRangeDurationRef.current
          )
          prevPreviewRangeStartRef.current = undefined
          prevPreviewRangeDurationRef.current = undefined
        }
      })
    },
    [selection]
  )

  useWindowResize(() => {
    maxTimelineHeightRef.current = document.body.clientHeight * 0.75
  })

  const resizeStart = React.useCallback(() => {
    bufferTimelineHeightRef.current = timeline.height
  }, [timeline])

  const handleResize = useThrottle({
    callback: React.useCallback(
      (deltaY) => {
        const nextValue = R.clamp(
          minTimelineHeightRef.current,
          maxTimelineHeightRef.current,
          bufferTimelineHeightRef.current + deltaY
        )

        timeline.updateHeight(nextValue)
      },
      [timeline]
    ),
    delay: 32,
  })

  const resizeEnd = React.useCallback(() => {
    bufferTimelineHeightRef.current = 0
  }, [timeline])

  const shiftVisibleRange = React.useCallback((delta: number) => {
    const newTime = timeline.visibleRangeStartTime + delta

    const isStart = newTime <= 0
    const isEnd = newTime + timeline.visibleRangeDuration >= projectDuration

    if (isStart) {
      timeline.updateVisibleRangeStartTime(0)
      return
    }

    if (isEnd) {
      timeline.updateVisibleRangeStartTime(
        projectDuration - timeline.visibleRangeDuration
      )
      return
    }

    timeline.updateVisibleRangeStartTime(newTime)
  }, [])

  const zoom = React.useCallback((delta: number) => {
    timeline.zoomBy(delta)
  }, [])

  const containerRef = React.useRef<HTMLDivElement>(null)
  const { isListening, offsetY, startListen } = useMouseMove()

  const startListenFn = React.useCallback(
    (e: MouseEvent) => {
      resizeStart()
      startListen(e)
    },
    [startListen]
  )

  const handleWheel = React.useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => {
      // @NOTE: if enabled then vertical scroll on timeline doesn't work but it prevents of default "back" gesture on mac.
      // @TODO: enable once we solve issue with vertical and horizontal scroll synchronization.

      if (e.metaKey || e.ctrlKey) {
        e.stopPropagation()
        e.preventDefault()
        const clampedDy = R.clamp(-50, 50, e.deltaY)
        const shiftStep = clampedDy * 0.001
        zoom(1 - shiftStep)
        return
      }

      const angle =
        (getVectorAngle({
          start: { x: 0, y: 0 },
          end: { x: e.deltaX, y: -e.deltaY },
        }) *
          180) /
        Math.PI

      if ((-45 < angle && angle < 45) || (135 < angle && angle < 225)) {
        e.stopPropagation()
        e.preventDefault()
      }

      const clampedDy = R.clamp(-50, 50, e.deltaX)
      const shiftStep = clampedDy * 0.001
      shiftVisibleRange(shiftStep)
    },
    []
  )

  React.useEffect(() => {
    if (isListening === false) {
      resizeEnd()
      return
    }

    handleResize(-offsetY)
  }, [isListening, offsetY])

  // @NOTE: required to do in this way because react does not allow to use wheel event with passive: false,
  // which required to prevent default behaviour.
  React.useEffect(() => {
    // @ts-ignore
    containerRef.current?.addEventListener('wheel', handleWheel, {
      passive: false,
    })

    return () => {
      // @ts-ignore
      containerRef.current?.removeEventListener('wheel', handleWheel)
    }
  }, [handleWheel])

  return (
    <ScrollSyncProvider>
      <div ref={containerRef} className={styles.body} onWheel={handleWheel}>
        <div
          className={classNames(styles.resizer, {
            [styles['resizer--active']]: isListening,
          })}
          // @ts-ignore
          onMouseDown={startListenFn}
        />
        {/* {children.dynamicPreviewRange && <DynamicPreviewRange />} */}

        <div className={styles['info-wrapper']} style={{ width }}>
          <Info />
        </div>

        <div
          className={styles['tracks-wrapper']}
          style={{ width: `calc(100% - ${width}px)` }}
        >
          <CommentsTimeline />

          <Tracks project={project} />
        </div>
      </div>
    </ScrollSyncProvider>
  )
})

Timeline.displayName = 'Timeline'
