import { Entity } from '@aninix-inc/model'
import { convertEntityToSnapshot as convertNodeToSnapshot } from '@aninix-inc/renderer'
import { featureFlags, ImagesStore } from '@aninix/core'
import { useReloadOnAnyUpdate } from '@aninix/core/updates'
import { useLogger } from '@aninix/logger'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SvgLayer } from './svg-layer'

export interface IProps {
  entity: Entity
  time: number
  imagesStore: ImagesStore
}
export const SvgLayerDataProvider: React.FCC<IProps> = observer(
  ({ entity, time, imagesStore }) => {
    const [, forceUpdate] = React.useState(0)

    useReloadOnAnyUpdate(entity)
    const logger = useLogger()
    const start = window.performance.now()
    const snapshot = convertNodeToSnapshot({
      entity,
      time,
      imagesStore,
    })

    React.useEffect(() => {
      logger.log(
        '!!! convertNodeToSnapshot in',
        window.performance.now() - start,
        'ms'
      )
    })

    return (
      <SvgLayer
        // @NOTE: imagesStore.isLoading is required to properly update the state when all images are loaded.
        // Find a better solution for this.
        key={`${snapshot.id}-${imagesStore.isLoading}`}
        rootSnapshot={snapshot}
        snapshot={snapshot}
        renderText={featureFlags.renderText}
      />
    )
  }
)

SvgLayerDataProvider.displayName = 'SvgLayerDataProvider'
