import { ProjectError } from '@aninix/core'
import * as Sentry from '@sentry/browser'

const getEventHash = (event: Sentry.ErrorEvent): string => {
  const type = event.exception?.values?.[0]?.type ?? ''
  const value = event.exception?.values?.[0]?.value ?? ''

  return `${type}:${value}`
}

const sentryEventCache = new Set<string>()
const CACHE_CLEANUP_INTERVAL = 1000

export const initSentry = (
  options?: Omit<Sentry.BrowserOptions, 'beforeSend'>
) =>
  Sentry.init({
    dsn: 'https://018bb42b8953952722409ad517c8ae5f@o4504077571457024.ingest.us.sentry.io/4507851087675392',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https?:\/\/(api|api-dev|app)\.aninix\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: async (event, hint) => {
      const hash = getEventHash(event)

      if (sentryEventCache.has(hash)) return null

      sentryEventCache.add(hash)
      setTimeout(() => sentryEventCache.delete(hash), CACHE_CLEANUP_INTERVAL)

      const error = hint.originalException

      if (error instanceof ProjectError) {
        hint.attachments = [
          {
            data: JSON.stringify(error.project),
            filename: 'project.json',
            contentType: 'application/json',
          },
        ]
      }
      console.log('[sentry] error to be sent', event, hint)
      return event
    },
    ...(options ?? {}),
  })
