import {
  ChildrenRelationsAspect,
  ColorStopsRelationsAspect,
  Component,
  DurationComponent,
  EffectsRelationsAspect,
  Entity,
  EntityType,
  FillsRelationsAspect,
  NodeColorComponent,
  PaintType,
  PaintTypeComponent,
  ParentRelationAspect,
  Root,
  SelectionSystem,
  StrokesRelationsAspect,
  TimeComponent,
  commitUndo,
  getDuration,
  getGroupOfSegments,
  getNode,
  getSortedKeyframes,
  getStartTime,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { TimelineTrack } from '@aninix/app-design-system'
import {
  KeyModificator,
  getSelection,
  useEntity,
  useProject,
  useSession,
  useSystem,
} from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import * as timeConverters from '../../../../helpers/timeConverters'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { GroupOfSegments as GroupOfKeyframes } from './group-of-segments'
import * as styles from './index.scss'

// TODO: refactor
const handlerWidth = 6

// @TODO: move to model package
export const createIsKeyframeInRange =
  (start: number, end: number) => (keyframe: Entity) =>
    keyframe.getComponentOrThrow(TimeComponent).value >= start &&
    keyframe.getComponentOrThrow(TimeComponent).value <= end

// @TODO: move to model package
export const getOwnAnimatedProperties = (node: Entity) =>
  node.components.filter(
    (component) => getSortedKeyframes(component).length > 0
  )

// @TODO: move to model package
const getAnimatedProperties = (node: Entity): Component[] => {
  const project = node.getProjectOrThrow()
  let cacheMap = project.cache.get<Map<string, unknown>>(node.id)

  if (cacheMap === undefined) {
    cacheMap = new Map<string, unknown>()
    project.cache.set(node.id, cacheMap)
  }

  const cachedValue = cacheMap.get('animated-properties') as
    | Component[]
    | undefined

  if (cachedValue !== undefined) {
    return cachedValue
  }

  const properties = node.components.filter(
    (component) => getSortedKeyframes(component).length > 0
  )

  if (node.hasAspect(ChildrenRelationsAspect)) {
    node
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
      .forEach((child) => {
        properties.push(...getAnimatedProperties(child))
      })
  }

  if (node.hasAspect(FillsRelationsAspect)) {
    node
      .getAspectOrThrow(FillsRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

        if (
          paintType === PaintType.GradientLinear ||
          paintType === PaintType.GradientRadial
        ) {
          paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()
            .forEach((colorStop) => {
              properties.push(...getAnimatedProperties(colorStop))
            })
        }

        properties.push(...getAnimatedProperties(paint))
      })
  }

  if (node.hasAspect(StrokesRelationsAspect)) {
    node
      .getAspectOrThrow(StrokesRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        properties.push(...getAnimatedProperties(paint))
      })
  }

  if (node.hasAspect(EffectsRelationsAspect)) {
    node
      .getAspectOrThrow(EffectsRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        properties.push(...getAnimatedProperties(paint))
      })
  }

  cacheMap.set('animated-properties', properties)

  return properties
}

// @TODO: replace by type from mode (when version would be >2.58.1)
type GroupOfKeyframes = {
  keyframes: Entity[]
  start: number
  end: number
}

export interface IProps {
  layer: Entity
  keyframes: Entity[]
  parentTrackWidth: number
}
export const Layer: React.FCC<IProps> = ({ layer, parentTrackWidth }) => {
  const analytics = useAnalytics()
  const session = useSession()
  const project = useProject()
  const root = project.getEntityByTypeOrThrow(Root)
  useEntity(layer)
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  const projectDuration = root.getComponentOrThrow(DurationComponent).value
  const groupsOfSegments = getGroupOfSegments(layer)
  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isSelfSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    layer.components
  )
  const node = getNode(layer)

  if (node === undefined) {
    throw new Error('Invalid state. Node is not found')
  }

  const startTime = getStartTime(node)
  const duration = getDuration(node)
  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    if (isComponentSelectedValue) {
      // return `${
      //   defaults.nodeColors[
      //     layer.getComponentOrThrow(NodeColorComponent).value
      //   ]
      // }10`
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    if (isSelfSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isSelfSelectedValue,
    isComponentSelectedValue,
  ])

  const selectNode = React.useCallback(() => {
    analytics.track({
      eventName: AnalyticsEvent.LayerSelected,
      properties: {
        context: 'webapp.editor.timeline.tracks',
      },
    })
    // @NOTE: also used in info part of timeline
    if (session.keyModificators.includes(KeyModificator.Shift)) {
      const selectedNodes = getSelection(project, EntityType.Node)
      if (selectedNodes.length === 0) {
        selection.select([layer.id])
        commitUndo(project)
        return
      }
      const selectedNodeIds = selectedNodes.map((_node) => _node.id)
      const parent = layer
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow()
      const nodesToSelect = parent
        .getAspectOrThrow(ChildrenRelationsAspect)
        .getChildrenList()
      const minSelectedIndex = R.findIndex(
        (_node) => selectedNodeIds.includes(_node.id),
        nodesToSelect
      )
      const currentIndex = R.findIndex(
        (_node) => _node.id === layer.id,
        nodesToSelect
      )
      const maxSelectedIndex = R.findLastIndex(
        (_node) => selectedNodeIds.includes(_node.id),
        nodesToSelect
      )
      // @NOTE: reversed
      if (currentIndex < minSelectedIndex) {
        R.reverse(
          R.range(
            R.min(currentIndex, minSelectedIndex),
            R.max(currentIndex, minSelectedIndex) + 1
          )
        ).forEach((i) => {
          if (nodesToSelect[i] == null) {
            return
          }
          selection.select([nodesToSelect[i].id])
        })
        commitUndo(project)
        return
      }
      R.range(
        R.min(currentIndex, maxSelectedIndex),
        R.max(currentIndex, maxSelectedIndex) + 1
      ).forEach((i) => {
        if (nodesToSelect[i] == null) {
          return
        }
        selection.select([nodesToSelect[i].id])
      })
      commitUndo(project)
      return
    }
    if (session.keyModificators.includes(KeyModificator.Ctrl)) {
      if (selection.isSelected(layer.id)) {
        selection.deselect([layer.id])
        return
      }
      selection.select([layer.id])
      commitUndo(project)
      return
    }
    selection.deselectAll().select([layer.id])
  }, [selection, layer])

  const enableHighlight = React.useCallback(() => {
    session.setBuffer(layer.id)
  }, [])

  const disableHighlight = React.useCallback(() => {
    session.cleanBuffer()
  }, [])

  const convertTimeToPixels = React.useCallback(
    (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        time,
      }),
    [projectDuration, parentTrackWidth]
  )

  const left = React.useMemo(
    () => convertTimeToPixels(startTime) + handlerWidth,
    [convertTimeToPixels, startTime]
  )

  const width = React.useMemo(
    () => convertTimeToPixels(duration),
    [convertTimeToPixels, duration]
  )

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      selectNode()
    },
    []
  )

  return (
    <TimelineTrack alignment="right">
      <div
        data-model-type="node"
        className={styles.background}
        style={{
          left,
          width,
          // @ts-ignore
          '--bg-color': color,
          overflow: 'visible',
        }}
        onMouseEnter={enableHighlight}
        onMouseLeave={disableHighlight}
        onClick={onClick}
      />
      {groupsOfSegments.map((groupOfSegments, idx) => (
        <GroupOfKeyframes
          key={idx}
          layer={layer}
          keyframes={groupOfSegments.keyframes}
          parentTrackWidth={parentTrackWidth}
          projectDuration={projectDuration}
          startTime={groupOfSegments.start}
          endTime={groupOfSegments.end}
        />
      ))}
    </TimelineTrack>
  )
}

Layer.displayName = 'Layer'
