import {
  deleteNode,
  Entity,
  EntityType,
  NodeType,
  NodeTypeComponent,
  VectorPathsComponent,
} from '@aninix-inc/model'
import { usePathEditor, useTools } from '@aninix/core'
import featureFlags from '@aninix/core/feature-flags'
import { useSelection } from '@aninix/editor/hooks/use-selection'
import { useUndoRedo } from '@aninix/editor/hooks/use-undo-redo'
import { useUpdates } from '@aninix/editor/hooks/use-updates'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useComponents, useSystem } from '../../../../updates'
import { Region } from './region'
import { removePathItems } from './utils'

export interface IProps {
  editable?: boolean
  selectionRectBoundingRect?: {
    x: number
    y: number
    width: number
    height: number
  }
}
export const SvgPathEditor: React.FCC<IProps> = observer(
  ({ editable = true, selectionRectBoundingRect }) => {
    const selection = useSelection()
    const updates = useUpdates()
    const undoRedo = useUndoRedo()
    const tools = useTools()
    const pathEditor = usePathEditor()
    useSystem(selection)

    const node = getVectorNode(
      selection.getEntitiesByEntityType(EntityType.Node)
    )

    const vectorPathComponent = node?.getComponent(VectorPathsComponent)
    useComponents(vectorPathComponent ? [vectorPathComponent] : [])

    const vectorPaths = vectorPathComponent?.value

    const showPathEditor = node && vectorPaths && editable

    const updateRegion = React.useCallback(
      (index: number, data: string) => {
        if (!node) return

        const component = node.getComponentOrThrow(VectorPathsComponent)
        const value = component.value.map((path, i) =>
          i === index ? { ...path, data } : path
        )
        console.log('[svg-path-editor] updateRegion: ', value)
        node.updateComponent(VectorPathsComponent, value)
      },
      [node, updates]
    )

    const removePoint = React.useCallback(
      (indexes: {
        regionIndex: number
        pathIndex: number
        pointIndex: number
      }) => {
        if (!node) return

        removePathItems(node, [indexes], () =>
          updates.batch(() => {
            selection.deselect([node.id])
            deleteNode(node)
            tools.changeTool(tools.prevTool)
          })
        )
        pathEditor.clear()
        undoRedo.commitUndo()
      },
      [node, updates]
    )

    React.useEffect(() => {
      return () => {
        pathEditor.clear()
      }
    }, [])

    if (!showPathEditor) return null

    return vectorPaths.map((vp: VectorPath, index: number) => (
      <Region
        key={`node-${node.id}-region-${index}`}
        node={node}
        vectorPath={vp}
        regionIndex={index}
        selectionRectBoundingRect={selectionRectBoundingRect}
        onUpdate={(data) => updateRegion(index, data)}
        onEndChange={undoRedo.commitUndo}
        onRemovePoint={(indexes) =>
          removePoint({ ...indexes, regionIndex: index })
        }
      />
    ))
  }
)

SvgPathEditor.displayName = 'SvgVertices'

const getVectorNode = (selectedNodes: Entity[]) => {
  if (selectedNodes.length !== 1) return null

  const node = selectedNodes[0]

  if (!node) return null

  const isText =
    featureFlags.renderText &&
    node?.getComponent(NodeTypeComponent)?.value === NodeType.Text

  if (isText) return null

  const vectorPaths = node?.getComponent(VectorPathsComponent)?.value

  if (!vectorPaths?.length) return null

  return node
}
