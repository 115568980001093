import {
  Component,
  Entity,
  FillsRelationsAspect,
  ParentRelationAspect,
  StrokesRelationsAspect,
  TargetRelationAspect,
  components,
} from '@aninix-inc/model'
import { Components } from './mappers/types'

/**
 * The class is responsible for determining the name of a property based on the type of the property and the keyframes associated with it.
 * @todo refactor it further to isolate full entity. And move it to entities folder.
 */
export class PropertyNameSegment {
  constructor(private readonly segment: [Entity, Entity]) {}

  get propertyName(): string {
    const targetComponent = this.segment[0]
      .getAspectOrThrow(TargetRelationAspect)
      .getTargetComponentOrThrow()
    return this.getValue(targetComponent)
  }

  private getValue = (providedComponent: Component): string => {
    const component = providedComponent as Components

    /* eslint-disable-next-line sonarjs/max-switch-cases */
    switch (true) {
      case component instanceof components.PositionComponent:
        return 'position'
      case component instanceof components.AnchorPointComponent:
        return 'anchor-point'
      case component instanceof components.SizeComponent:
        return 'size'
      case component instanceof components.ShadowOffsetComponent:
        return 'shadow-offset'
      case component instanceof components.SkewComponent:
        return 'skew'
      case component instanceof components.ScaleComponent:
        return 'scale'
      case component instanceof components.OpacityComponent:
        return 'opacity'
      case component instanceof components.ProgressComponent:
        return 'progress'
      case component instanceof components.TrimStartComponent:
        return 'trim-start'
      case component instanceof components.TrimOffsetComponent:
        return 'trim-offset'
      case component instanceof components.TrimEndComponent:
        return 'trim-end'

      case component instanceof components.BlurRadiusComponent:
        return 'blur-radius'
      case component instanceof components.BottomLeftCornerRadiusComponent:
        return 'border-bottom-left-radius'
      case component instanceof components.BottomRightCornerRadiusComponent:
        return 'border-bottom-right-radius'
      case component instanceof components.TopLeftCornerRadiusComponent:
        return 'border-top-left-radius'
      case component instanceof components.TopRightCornerRadiusComponent:
        return 'border-top-right-radius'
      case component instanceof components.CornerRadiusComponent:
        return 'corner-radius'
      case component instanceof components.DashComponent:
        return 'stroke-dasharray / dash'
      case component instanceof components.GapComponent:
        return 'stroke-dasharray / gap'
      case component instanceof components.DashOffsetComponent:
        return 'stroke-dashoffset'
      case component instanceof components.InnerRadiusComponent:
        return 'inner-radius'
      case component instanceof components.PointCountComponent:
        return 'points-count'
      case component instanceof components.ShadowRadiusComponent:
        return 'shadow-radius'
      case component instanceof components.ShadowSpreadComponent:
        return 'shadow-spread'
      case component instanceof components.StrokeTopWeightComponent:
        return 'border-top-width'
      case component instanceof components.StrokeBottomWeightComponent:
        return 'border-bottom-width'
      case component instanceof components.StrokeRightWeightComponent:
        return 'border-right-width'
      case component instanceof components.StrokeLeftWeightComponent:
        return 'border-left-width'
      case component instanceof components.StrokeWeightComponent:
        return 'border-width'

      case component instanceof components.StartAngleComponent:
        return 'starting-angle'
      case component instanceof components.EndAngleComponent:
        return 'starting-angle'
      case component instanceof components.RotationComponent:
        return 'rotation'

      case component instanceof components.ShadowColorComponent:
        return 'box-shadow / color'

      case component instanceof components.RgbaValueComponent: {
        const componentTag = component.entity
          .getAspectOrThrow(ParentRelationAspect)
          .getRelationComponentTagOrThrow()

        if (componentTag === FillsRelationsAspect.tag) {
          return 'background-color'
        }

        if (componentTag === StrokesRelationsAspect.tag) {
          return 'border-color'
        }

        return 'color'
      }

      case component instanceof components.GradientTransformComponent:
      case component instanceof components.ImageTransformComponent: {
        return 'transform'
      }

      // @NOTE: handle not targetable components
      case component instanceof components.BlendModeComponent:
      case component instanceof components.ChildrenExpandedComponent:
      case component instanceof components.ClipContentComponent:
      case component instanceof components.DurationComponent:
      case component instanceof components.EffectTypeComponent:
      case component instanceof components.EntityTypeComponent:
      case component instanceof components.EntryComponent:
      case component instanceof components.FpsComponent:
      case component instanceof components.HashComponent:
      case component instanceof components.IndividualCornerRadiusComponent:
      case component instanceof components.IndividualStrokeWeightComponent:
      case component instanceof components.LockedComponent:
      case component instanceof components.MaskComponent:
      case component instanceof components.MatrixTransformValueComponent:
      case component instanceof components.NameComponent:
      case component instanceof components.NumberValueComponent:
      case component instanceof components.NodeColorComponent:
      case component instanceof components.NodeTypeComponent:
      case component instanceof components.PaintTypeComponent:
      case component instanceof components.PathReversedComponent:
      case component instanceof components.Point2dValueComponent:
      case component instanceof components.PropertiesExpandedComponent:
      case component instanceof components.RenderScaleTypeComponent:
      case component instanceof components.RenderScaleComponent:
      case component instanceof components.RenderSuffixComponent:
      case component instanceof components.RenderTypeComponent:
      case component instanceof components.ScaleLockedComponent:
      case component instanceof components.ScaleTypeComponent:
      case component instanceof components.ScalingFactorComponent:
      case component instanceof components.SpatialPoint2dValueComponent:
      case component instanceof components.SizeBehaviourComponent:
      case component instanceof components.SizeLockedComponent:
      case component instanceof components.SmoothCornerRadiusComponent:
      case component instanceof components.SoloComponent:
      case component instanceof components.SpringCurveComponent:
      case component instanceof components.StartTimeComponent:
      case component instanceof components.StrokeAlignComponent:
      case component instanceof components.StrokeCapStartComponent:
      case component instanceof components.StrokeCapEndComponent:
      case component instanceof components.StrokeJoinComponent:
      case component instanceof components.TimeComponent:
      case component instanceof components.TimingCurveComponent:
      case component instanceof components.VectorPathsComponent:
      case component instanceof components.VisibleInViewportComponent:
      case component instanceof components.MainNodeIdComponent:
      case component instanceof components.PresetIdComponent:
      case component instanceof components.CoverTimeComponent:
      case component instanceof components.InitialNodeIdComponent:
      case component instanceof components.MetadataComponent:
      case component instanceof components.TextAlignHorizontalComponent:
      case component instanceof components.TextAlignVerticalComponent:
      case component instanceof components.TextAutoResizeComponent:
      case component instanceof components.TextCaseComponent:
      case component instanceof components.TextDecorationComponent:
      case component instanceof components.TextListOptionsComponent:
      case component instanceof components.TextTruncationComponent:
      case component instanceof components.AutoRenameComponent:
      case component instanceof components.FontStyleComponent:
      case component instanceof components.FontNameComponent:
      case component instanceof components.FontSizeComponent:
      case component instanceof components.FontWeightComponent:
      case component instanceof components.VersionComponent:
      case component instanceof components.CharactersComponent:
      case component instanceof components.StartIndexComponent:
      case component instanceof components.EndIndexComponent:
      case component instanceof components.ParagraphIndentComponent:
      case component instanceof components.ParagraphSpacingComponent:
      case component instanceof components.HangingListComponent:
      case component instanceof components.HangingPunctuationComponent:
      case component instanceof components.MaxLinesComponent:
      case component instanceof components.IndetationComponent:
      case component instanceof components.LineHeightUnitComponent:
      case component instanceof components.LineHeightValueComponent:
      case component instanceof components.LetterSpacingUnitComponent:
      case component instanceof components.LetterSpacingValueComponent:
      case component instanceof components.LeadingTrimComponent:
      case component instanceof components.ListSpacingComponent:
      case component instanceof components.EnabledComponent:
      case component instanceof components.ExpressionComponent: {
        throw new Error(`${component} is not supported in such context`)
      }

      default: {
        const never: never = component
        throw new Error(`"${never}" should never be called`)
      }
    }
  }
}
