import { Project } from '@aninix-inc/model'

export class ProjectError extends Error {
  constructor(
    message: string,
    public readonly project: Project
  ) {
    super(message)
    this.name = 'ProjectError'
  }
}
