import { getStripeCustomerPortal } from '@aninix/api'
import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { GeneralAccessRestricted } from '../../components/status-pages'

export type Props = {}
export const ManageSubscription: React.FCC<Props> = observer(() => {
  const [url, setUrl] = React.useState<string | undefined>()

  const redirectToCustomerPortal = React.useCallback(async () => {
    try {
      const result = (await getStripeCustomerPortal()).data
      setUrl(result.url)
      return result.url
    } catch (err: unknown) {
      // @TODO: add custom error handling logic
      console.error('Error happen while generating customer portal url', err)
      setUrl('')
      return ''
    }
  }, [])

  React.useEffect(() => {
    redirectToCustomerPortal().then((url) => {
      if (url !== '') {
        window.location.assign(url)
      }
    })
  }, [])

  if (url === '') {
    return <GeneralAccessRestricted />
  }

  return <FullPageLoader />
})

ManageSubscription.displayName = 'ManageSubscription'
