export { convertEntityToSnapshot as convertNodeToSnapshot } from '@aninix-inc/renderer'
export { TimeFormat } from './constants'
export { default as featureFlags } from './feature-flags'
export * from './interfaces/plugins'
export * from './interfaces/providers'
export { FpsStats } from './modules/fps-stats'
// @NOTE: export disabled so it's not included in figma-plugin
// @TODO: refactor so in build files we only include required code
// export { Inspector } from './modules/inspector'
export { ProjectError } from './exceptions/project-error'
export { CommentContextProvider } from './modules/comments-wrapper/use-cases/use-comment-context'
export type { IProps as IInspectorProps } from './modules/inspector'
export { Preview } from './modules/preview'
export { Renderer as RendererModule } from './modules/renderer'
export { Viewport as ViewportModule } from './modules/viewport'
export * from './registries'
export * from './stores'
export * from './updates'
export * as useCases from './use-cases'
export * from './utils'
export { svgPathToBezierPoints } from './vector-helpers'

export const safeString = (string: string): string => string.replaceAll(':', '')
