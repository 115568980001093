import {
  AnchorPointComponent,
  Entity,
  FpsComponent,
  LayoutAspect,
  OpacityComponent,
  Point2dKeyframe,
  Point2dValueComponent,
  PositionComponent,
  Root,
  RotationComponent,
  ScaleComponent,
  SkewComponent,
  SpatialPoint2dValueComponent,
  TimeComponent,
  TimingCurveComponent,
  getTimingCurveKeyframes,
  getTransformMatrix,
} from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { convertRadiansToDegrees as degreesFromRadians } from '../../../../utils/convert-radians-to-degrees'
import { getVectorMagnitude as vectorMagnitude } from '../../../../utils/get-vector-magnitude'
import { Vector } from '../../../../utils/types'
import { LottieLayerBase } from '../types'
import { Property } from './property'

function safeSign(sign: number): number {
  return sign === 0 ? 1 : sign
}

/**
 * We have copied function here because it's not ignore an angle.
 */
function vectorAngle(vector: Vector): number {
  const { start, end } = vector
  const dotProduct = start.x * end.x + start.y * end.y
  const magnitude1 = Math.sqrt(start.x ** 2 + start.y ** 2)
  const magnitude2 = Math.sqrt(end.x ** 2 + end.y ** 2)

  if (magnitude1 === 0 || magnitude2 === 0) {
    return 0
  }

  const cosAngle = dotProduct / (magnitude1 * magnitude2)
  const angleInRadians = Math.acos(cosAngle)
  return angleInRadians
}

export function TransformProperties(node: Entity): LottieLayerBase['ks'] {
  const position = Property<number[]>({
    property: node.getComponentOrThrow(PositionComponent),
    // @NOTE: mapping required to make anchor point at center of the layer
    // because in our model it sits on top left corner, but in lottie it sits in the center.
    // Multiply by scale is required to make proper shift of anchor point if user changed it's default position.
    mapper: ({ time }) => {
      const matrix = new paper.Matrix(
        getTransformMatrix({
          entity: node,
          time,
        })
      )
      const point = matrix.transform(
        new paper.Point(node.getComponentOrThrow(AnchorPointComponent).value)
      )
      return [point.x, point.y]
    },
    // @NOTE: making spatial interpolation here
    additionalMappers: (keyframe, nextKeyframe) => {
      // @NOTE: in tangent of the next keyframe
      let ti: [number, number] = [
        nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
          .tx1 -
          nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
            .x,
        nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
          .ty1 -
          nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
            .y,
      ]

      // @NOTE: out tangent of the current keyframe
      let to: [number, number] = [
        keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.tx2 -
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.x,
        keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.ty2 -
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.y,
      ]

      // @NOTE: when keyframes are equal we have to prevent any tangent animations
      if (
        keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.x ===
          nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
            .x &&
        keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.y ===
          nextKeyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value.y
      ) {
        ti = [0, 0]
        to = [0, 0]
      }

      return { ti, to }
    },
  })

  return {
    a: Property<number[]>({
      property: node.getComponentOrThrow(AnchorPointComponent),
      mapper: ({ value }) => [value.x, value.y],
    }),
    o: Property<number | number[]>({
      property: node.getComponentOrThrow(OpacityComponent),
      mapper: ({ value, isAnimated }) =>
        isAnimated ? [value * 100] : value * 100,
    }),
    p: position,
    // @NOTE: required to add minus sign because of different angle conversion in lottie and aninix
    r: Property<number | number[]>({
      property: node.getComponentOrThrow(RotationComponent),
      // @NOTE: for some reason lottie render sign depends on the scale Y
      // @NOTE: related to the issue ANI-1178. For flipped layers by Y we have to properly rotate them.
      // So firstly we found delta and then apply proper sign to it.
      mapper: ({ value, isAnimated }) => {
        const initial = node.getAspectOrThrow(LayoutAspect).initialRotation
        let sign = Math.sign(node.getComponentOrThrow(ScaleComponent).value.y)
        // @NOTE: Related to ANI-2490. Required to properly rotate layers which flipped by scale.y.
        sign = sign < 0 ? sign : -sign
        const delta = value - initial
        const fixedDelta = sign >= 0 ? -1 * delta : delta
        const valueAndDelta = initial + fixedDelta
        const newValue = sign === 0 ? valueAndDelta : sign * valueAndDelta
        // @ts-ignore
        return isAnimated ? [newValue] : newValue
      },
    }),
    s: Property<number[]>({
      property: node.getComponentOrThrow(ScaleComponent),
      // @NOTE: 0.1% required to properly render outer stroke on the web.
      // For some reason it cuts off if animation starts from 0.
      mapper: ({ value }) => {
        const xSign = value.x >= 0 ? 1 : -1
        const ySign = value.y >= 0 ? 1 : -1

        return [
          xSign * Math.max(0.1, Math.abs(value.x) * 100),
          ySign * Math.max(0.1, Math.abs(value.y) * 100),
        ]
      },
    }),
    sk: Property<number | number[]>({
      property: node.getComponentOrThrow(SkewComponent),
      mapper: ({ value, isAnimated }) => {
        const dist = vectorMagnitude({
          start: { x: 0, y: 0 },
          end: value,
        })
        // @NOTE: required to determine which direction should be used.
        const sign =
          safeSign(-1 * Math.sign(value.x)) * safeSign(Math.sign(value.y))

        if (isAnimated) {
          return [dist * sign]
        }

        return dist * sign
      },
    }),
    /**
     * Aninix has point animation for skew, but lottie has magnitude + angle.
     * In that case when first animation starts we should replace first keyframe
     * with the second one.
     * @example If we have 4 keyframes:
     * magnitude: ---A---B----C----D--------->
     * angle:     ---B---B----C----D--------->
     */
    sa: (() => {
      const project = node.getProjectOrThrow()
      const projectFps = project
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(FpsComponent).value
      const skew = node.getComponentOrThrow(SkewComponent)
      const keyframes = getTimingCurveKeyframes(skew)

      if (keyframes.length >= 2) {
        return {
          a: 1 as const,
          k: keyframes.flatMap(
            (
              keyframe: Point2dKeyframe,
              idx: number,
              keyframes: Point2dKeyframe[]
            ) => {
              const isFirst = idx === 0
              const isLast = idx === keyframes.length - 1
              const value = degreesFromRadians(
                vectorAngle({
                  start: { x: 1, y: 0 },
                  end: {
                    x: keyframe.getComponentOrThrow(Point2dValueComponent).value
                      .x,
                    y: keyframe.getComponentOrThrow(Point2dValueComponent).value
                      .y,
                  },
                })
              )

              if (isLast) {
                return [
                  {
                    t:
                      keyframe.getComponentOrThrow(TimeComponent).value *
                      projectFps,
                    s: [value],
                  },
                ]
              }

              const nextKeyframe = keyframes[idx + 1]

              if (isFirst) {
                const nextValue = degreesFromRadians(
                  vectorAngle({
                    start: { x: 1, y: 0 },
                    end: {
                      x: nextKeyframe.getComponentOrThrow(Point2dValueComponent)
                        .value.x,
                      y: nextKeyframe.getComponentOrThrow(Point2dValueComponent)
                        .value.y,
                    },
                  })
                )

                return [
                  {
                    t:
                      keyframe.getComponentOrThrow(TimeComponent).value *
                      projectFps,
                    s: [nextValue],
                    o: {
                      x: [
                        keyframe.getComponentOrThrow(TimingCurveComponent).value
                          .out.x,
                      ],
                      y: [
                        keyframe.getComponentOrThrow(TimingCurveComponent).value
                          .out.y,
                      ],
                    },
                    i: {
                      x: [
                        nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                          .value.in.x,
                      ],
                      y: [
                        nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                          .value.in.y,
                      ],
                    },
                  },
                ]
              }

              return [
                {
                  t:
                    keyframe.getComponentOrThrow(TimeComponent).value *
                    projectFps,
                  s: [value],
                  o: {
                    x: [
                      keyframe.getComponentOrThrow(TimingCurveComponent).value
                        .out.x,
                    ],
                    y: [
                      keyframe.getComponentOrThrow(TimingCurveComponent).value
                        .out.y,
                    ],
                  },
                  i: {
                    x: [
                      nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                        .value.in.x,
                    ],
                    y: [
                      nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                        .value.in.y,
                    ],
                  },
                },
              ]
            }
          ),
        }
      }

      return {
        a: 0 as const,
        k: degreesFromRadians(
          vectorAngle({
            start: { x: 0, y: 0 },
            end: { x: skew.value.x, y: skew.value.y },
          })
        ),
      }
    })(),
  }
}

export const defaultTransformProperties: LottieLayerBase['ks'] = {
  a: {
    a: 0,
    k: [0, 0],
  },
  p: {
    a: 0,
    k: [0, 0],
  },
  s: {
    a: 0,
    k: [100, 100],
  },
  sk: {
    a: 0,
    k: 0,
  },
  sa: {
    a: 0,
    k: 0,
  },
  r: {
    a: 0,
    k: 0,
  },
  o: {
    a: 0,
    k: 100,
  },
}
